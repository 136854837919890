import store from '@/store';
import FloweringAPI from '@/store/api/flowering';
import { DateData, RawChart } from '@/store/models/charts';
import { EstimatedDMPercentIncrease } from '@/store/models/configuration';
import {
  CanUploadFloweringImage,
  CanUploadFruitCountImage,
  CanUploadFruitSizeImage,
  DeleteFlowering,
  DeleteFruitCount,
  DeleteRawFlowering,
  FarmYieldByWeekTransposed,
  FlowerEvent,
  Flowering,
  FruitCount,
  MultipleFlowerEvent,
  PaginatedFlowerEvent,
  RawFloweringData,
  RawFloweringHarvestDate,
  RawFloweringPatchData,
  RawTransposedFlowerings,
  YieldByWeekTransposed
} from '@/store/models/flowering';
import { QTablePagination } from '@/store/models/meta';
import {
  Action,
  Module,
  MutationAction,
  VuexModule,
  getModule
} from 'vuex-module-decorators';

@Module({ name: 'FloweringStore', dynamic: true, store })
class FloweringStore extends VuexModule {
  StartDate = '';
  EndDate = '';
  FirstDate = '';
  LastDate = '';
  FloweringFilterDialog = false;
  FruitCountFilterDialog = false;
  RawFloweringBatchUploadDialog = false;
  RawFloweringDeleteDialog = false;
  FloweringBatchUploadDialog = false;
  FruitCountBatchUploadDialog = false;
  DryMatterBatchUploadDialog = false;
  FruitSizeBatchUploadDialog = false;

  FruitCountDownloadDialog = false;
  DryMatterDownloadDialog = false;
  FloweringDownloadDialog = false;
  RawFloweringDownloadDialog = false;
  FruitSizeDownloadDialog = false;

  TreeBatchUploadDialog = false;

  FruitCountDeleteDialog = false;
  FloweringDeleteDialog = false;
  FruitSizeDeleteDialog = false;
  DryMatterDeleteDialog = false;

  Flowerings: { [key: string]: Flowering[] } = {};
  FruitCounts: { [key: string]: FruitCount[] } = {};

  FlowerEventHeatUnitGraph: RawChart = {} as RawChart;
  MinMaxTemperatureGraph: RawChart = {} as RawChart;
  FlowerDistributionGraph: RawChart = {} as RawChart;
  FlowerLinearGraph: RawChart = {} as RawChart;
  FruitDistributionGraph: RawChart = {} as RawChart;
  DryMatterDistributionGraph: RawChart = {} as RawChart;
  DryMatterPercentIncreaseGraph: RawChart = {} as RawChart;
  PackHouseGraph: RawChart = {} as RawChart;

  FloweringDateList: DateData[] = [] as DateData[];
  FloweringDateVersion = 0;
  FloweringLayerVersion = 0;

  FruitCountDateList: DateData[] = [] as DateData[];
  FruitCountDateVersion = 0;
  FruitCountLayerVersion = 0;

  DryMatterDateList: DateData[] = [] as DateData[];
  DryMatterDateVersion = 0;
  DryMatterLayerVersion = 0;

  FruitSizeDateList: DateData[] = [] as DateData[];
  FruitSizeDateVersion = 0;
  FruitSizeLayerVersion = 0;

  updateLayers = 0;

  SelectedBlock: number | null = null;

  FlowerEvents: PaginatedFlowerEvent = {} as PaginatedFlowerEvent;
  AddEditFlowerEvent: FlowerEvent = {} as FlowerEvent;
  emptyFlowerEvent: FlowerEvent = {} as FlowerEvent;

  AddEditFruitCount: FruitCount = {} as FruitCount;

  RawTransposedFlowerings: RawTransposedFlowerings = {} as RawTransposedFlowerings;
  RawFloweringHarvestDate: RawFloweringHarvestDate = {} as RawFloweringHarvestDate;

  YieldByWeekTransposed: YieldByWeekTransposed = {} as YieldByWeekTransposed;
  FarmYieldByWeekTransposed: FarmYieldByWeekTransposed = {} as FarmYieldByWeekTransposed;

  FlowerEventTablePagination: QTablePagination = {
    sortBy: 'id',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 1
  };

  get DateList() {
    return this.FloweringDateList.map(x => x.date);
  }

  @MutationAction
  async setFlowerEventTablePagination(data: QTablePagination) {
    return { FlowerEventTablePagination: data };
  }

  @MutationAction
  async setFloweringStartDate(date = '') {
    return { StartDate: date };
  }

  @MutationAction
  async setFloweringEndDate(date = '') {
    return { EndDate: date };
  }

  @MutationAction
  async setFloweringFirstDate(date = '') {
    return { FirstDate: date };
  }

  @MutationAction
  async setFloweringLastDate(date = '') {
    return { LastDate: date };
  }

  @MutationAction
  async setFloweringFilterDialog(value = false) {
    return { FloweringFilterDialog: value };
  }

  @MutationAction
  async setFruitCountFilterDialog(value = false) {
    return { FruitCountFilterDialog: value };
  }

  @MutationAction
  async setTreeBatchUploadDialog(value = false) {
    return { TreeBatchUploadDialog: value };
  }

  @MutationAction
  async setRawFloweringBatchUploadDialog(value = false) {
    return { RawFloweringBatchUploadDialog: value };
  }

  @MutationAction
  async setRawFloweringDeleteDialog(value = false) {
    return { RawFloweringDeleteDialog: value };
  }

  @MutationAction
  async setRawFloweringDownloadDialog(value = false) {
    return { RawFloweringDownloadDialog: value };
  }

  @MutationAction
  async setFloweringBatchUploadDialog(value = false) {
    return { FloweringBatchUploadDialog: value };
  }

  @MutationAction
  async setFloweringDownloadDialog(value = false) {
    return { FloweringDownloadDialog: value };
  }

  @MutationAction
  async setFruitCountBatchUploadDialog(value = false) {
    return { FruitCountBatchUploadDialog: value };
  }

  @MutationAction
  async setFruitCountDownloadDialog(value = false) {
    return { FruitCountDownloadDialog: value };
  }

  @MutationAction
  async setDryMatterBatchUploadDialog(value = false) {
    return { DryMatterBatchUploadDialog: value };
  }

  @MutationAction
  async setDryMatterDownloadDialog(value = false) {
    return { DryMatterDownloadDialog: value };
  }

  @MutationAction
  async setFruitSizeBatchUploadDialog(value = false) {
    return { FruitSizeBatchUploadDialog: value };
  }

  @MutationAction
  async setFloweringDeleteDialog(value = false) {
    return { FloweringDeleteDialog: value };
  }

  @MutationAction
  async setFruitSizeDeleteDialog(value = false) {
    return { FruitSizeDeleteDialog: value };
  }

  @MutationAction
  async setFruitSizeDownloadDialog(value = false) {
    return { FruitSizeDownloadDialog: value };
  }

  @MutationAction
  async setFruitCountDeleteDialog(value = false) {
    return { FruitCountDeleteDialog: value };
  }

  @MutationAction
  async setDryMatterDeleteDialog(value = false) {
    return { DryMatterDeleteDialog: value };
  }

  @MutationAction
  async setSelectedBlock(value: number | null = null) {
    return { SelectedBlock: value };
  }

  @MutationAction
  async setUpdateLayers(value: number) {
    return { updateLayers: value };
  }

  @Action
  async batchUploadRawFlowering(params: { data: FormData }) {
    const response = await FloweringAPI.batchUploadRawFlowerings(params.data);
    return response;
  }

  @Action
  async updateRawFloweringProcessingStatus(id: number) {
    const response = await FloweringAPI.updateRawFloweringProcessingStatus(id);
    return response;
  }

  @Action
  async findBatchDeleteRawFlowering(data: DeleteRawFlowering) {
    const response = await FloweringAPI.findBatchDeleteRawFlowering(data);
    return response;
  }

  @Action
  async batchDeleteRawFlowering(data: DeleteRawFlowering) {
    const response = await FloweringAPI.batchDeleteRawFlowering(data);
    return response;
  }

  @Action
  async batchUploadFlowering(params: { data: FormData }) {
    const response = await FloweringAPI.batchUploadFlowerings(params.data);
    return response;
  }

  @Action
  async updateFloweringProcessingStatus(id: number) {
    const response = await FloweringAPI.updateFloweringProcessingStatus(id);
    return response;
  }

  @Action
  async downloadFloweringData(params: { data: FormData }) {
    const response = await FloweringAPI.downloadFloweringsData(params.data);
    return response;
  }

  @Action
  async batchUploadTree(params: { data: FormData }) {
    const response = await FloweringAPI.batchUploadTrees(params.data);
    return response;
  }

  @Action
  async updateTreeProcessingStatus(id: number) {
    const response = await FloweringAPI.updateTreeProcessingStatus(id);
    return response;
  }

  @Action
  async batchUploadFruitSize(params: { data: FormData }) {
    const response = await FloweringAPI.batchUploadFruitSizes(params.data);
    return response;
  }

  @Action
  async updateFruitSizeProcessingStatus(id: number) {
    const response = await FloweringAPI.updateFruitSizeProcessingStatus(id);
    return response;
  }

  @Action
  async canUploadMultipleFruitSizeImage(data: CanUploadFruitSizeImage[]) {
    const response = await FloweringAPI.canUploadMultipleFruitSizeImage(data);
    return response;
  }

  @Action
  async downloadFruitSizeData(params: { data: FormData }) {
    const response = await FloweringAPI.downloadFruitSizeData(params.data);
    return response;
  }

  @Action
  async findBatchDeleteFruitSize(data: DeleteFruitCount) {
    const response = await FloweringAPI.findBatchDeleteFruitSize(data);
    return response;
  }

  @Action
  async batchDeleteFruitSize(data: DeleteFruitCount) {
    const response = await FloweringAPI.batchDeleteFruitSize(data);
    return response;
  }

  @Action
  async canUploadFloweringImage(data: CanUploadFloweringImage) {
    const response = await FloweringAPI.canUploadFloweringImage(data);
    return response;
  }

  @Action
  async canUploadMultipleFloweringImage(data: CanUploadFloweringImage[]) {
    const response = await FloweringAPI.canUploadMultipleFloweringImage(data);
    return response;
  }

  @Action
  async findBatchDeleteFruitCount(data: DeleteFruitCount) {
    const response = await FloweringAPI.findBatchDeleteFruitCount(data);
    return response;
  }

  @Action
  async batchDeleteFruitCount(data: DeleteFruitCount) {
    const response = await FloweringAPI.batchDeleteFruitCount(data);
    return response;
  }

  @MutationAction
  async getFloweringsDates(params: {
    dateStart: string;
    farm: number;
    dateEnd?: string;
    block?: number | null;
  }) {
    let startDate = this.StartDate;
    let endDate = this.EndDate;

    let query = '';
    if (params) {
      query += '?';
      if (params.dateStart) {
        query += `&date_after=${params.dateStart}`;
      }
      if (params.dateEnd) {
        query += `&date_before=${params.dateEnd}`;
      }
      if (params.farm) {
        query += `&block__farm=${params.farm}`;
      }
      if (params.block) {
        query += `&block=${params.block}`;
      }
    }
    const dateList = await FloweringAPI.getFloweringsDates(query);
    if (dateList.length > 0) {
      startDate = dateList[0].date;
      endDate = dateList[dateList.length - 1].date;
    }
    return {
      FloweringDateList: dateList,
      StartDate: startDate,
      EndDate: endDate,
      FloweringDateVersion: new Date().getTime()
    };
  }

  @MutationAction
  async getFlowerings(params: {
    dateStart: string;
    dateEnd?: string;
    farm: number;
  }) {
    const flowerings = this.Flowerings;
    const selectedBlock = this.SelectedBlock;
    const version = new Date().getTime();
    if (
      params.dateEnd &&
      params.dateEnd == params.dateStart &&
      flowerings[`${params.dateStart}_${params.farm}_${selectedBlock}`]
    ) {
      return { Flowerings: flowerings, FloweringLayerVersion: version };
    }
    let query = '';
    if (params) {
      query += '?';
      if (params.dateStart) {
        query += `&date_after=${params.dateStart}`;
      }
      if (params.dateEnd) {
        query += `&date_before=${params.dateEnd}`;
      }
      if (selectedBlock) {
        query += `&block=${selectedBlock}`;
      }
      if (params.farm) {
        query += `&block__farm=${params.farm}`;
      }
    }
    if (params.dateStart && params.farm) {
      const newFlowerings = await FloweringAPI.getFlowerings(query);
      if (params.dateEnd && params.dateEnd != params.dateStart) {
        flowerings['ranged'] = newFlowerings;
      } else {
        flowerings[
          `${params.dateStart}_${params.farm}_${selectedBlock}`
        ] = newFlowerings;
      }
    }
    return { Flowerings: flowerings, FloweringLayerVersion: version };
  }

  @Action
  async batchUploadFruitCount(params: { data: FormData }) {
    const response = await FloweringAPI.batchUploadFruitCount(params.data);
    return response;
  }

  @Action
  async updateFruitCountProcessingStatus(id: number) {
    const response = await FloweringAPI.updateFruitCountProcessingStatus(id);
    return response;
  }

  @Action
  async canUploadFruitCountImage(data: CanUploadFruitCountImage) {
    const response = await FloweringAPI.canUploadFruitCountImage(data);
    return response;
  }

  @Action
  async canUploadMultipleFruitCountImage(data: CanUploadFruitCountImage[]) {
    const response = await FloweringAPI.canUploadMultipleFruitCountImage(data);
    return response;
  }

  @Action
  async downloadFruitCountData(params: { data: FormData }) {
    const response = await FloweringAPI.downloadFruitCountData(params.data);
    return response;
  }

  @Action
  async batchUploadDryMatter(params: { data: FormData }) {
    const response = await FloweringAPI.batchUploadDryMatter(params.data);
    return response;
  }

  @Action
  async updateDryMatterProcessingStatus(id: number) {
    const response = await FloweringAPI.updateDryMatterProcessingStatus(id);
    return response;
  }

  @Action
  async downloadDryMatterData(params: { data: FormData }) {
    const response = await FloweringAPI.downloadDryMatterData(params.data);
    return response;
  }

  @Action
  async findBatchDeleteDryMatter(data: DeleteFruitCount) {
    const response = await FloweringAPI.findBatchDeleteDryaMatter(data);
    return response;
  }

  @Action
  async batchDeleteDryMatter(data: DeleteFruitCount) {
    const response = await FloweringAPI.batchDeleteDryMatter(data);
    return response;
  }

  @Action
  async findBatchDeleteFlowering(data: DeleteFlowering) {
    const response = await FloweringAPI.findBatchDeleteFlowering(data);
    return response;
  }

  @Action
  async batchDeleteFlowering(data: DeleteFlowering) {
    const response = await FloweringAPI.batchDeleteFlowering(data);
    return response;
  }

  @MutationAction
  async getFruitCountDates(params: {
    dateStart: string;
    farm: number;
    dateEnd?: string;
    block?: number | null;
  }) {
    let startDate = this.StartDate;
    let endDate = this.EndDate;

    let query = '';
    if (params) {
      query += '?';
      if (params.dateStart) {
        query += `&date_after=${params.dateStart}`;
      }
      if (params.dateEnd) {
        query += `&date_before=${params.dateEnd}`;
      }
      if (params.farm) {
        query += `&block__farm=${params.farm}`;
      }
      if (params.block) {
        query += `&block=${params.block}`;
      }
    }
    const dateList = await FloweringAPI.getFruitCountDates(query);
    if (dateList.length > 0) {
      startDate = dateList[0].date;
      endDate = dateList[dateList.length - 1].date;
    }
    return {
      FruitCountDateList: dateList,
      StartDate: startDate,
      EndDate: endDate,
      FruitCountDateVersion: new Date().getTime()
    };
  }

  @MutationAction
  async getDryMatterDates(params: {
    dateStart: string;
    farm: number;
    dateEnd?: string;
    block?: number | null;
  }) {
    let startDate = this.StartDate;
    let endDate = this.EndDate;

    let query = '';
    if (params) {
      query += '?';
      if (params.dateStart) {
        query += `&date_after=${params.dateStart}`;
      }
      if (params.dateEnd) {
        query += `&date_before=${params.dateEnd}`;
      }
      if (params.farm) {
        query += `&block__farm=${params.farm}`;
      }
      if (params.block) {
        query += `&block=${params.block}`;
      }
    }
    const dateList = await FloweringAPI.getDryMattertDates(query);
    if (dateList.length > 0) {
      startDate = dateList[0].date;
      endDate = dateList[dateList.length - 1].date;
    }
    return {
      DryMatterDateList: dateList,
      StartDate: startDate,
      EndDate: endDate,
      DryMatterDateVersion: new Date().getTime()
    };
  }

  @MutationAction
  async getFruitSizeDates(params: {
    dateStart: string;
    farm: number;
    dateEnd?: string;
    block?: number | null;
  }) {
    let startDate = this.StartDate;
    let endDate = this.EndDate;

    let query = '';
    if (params) {
      query += '?';
      if (params.dateStart) {
        query += `&date_after=${params.dateStart}`;
      }
      if (params.dateEnd) {
        query += `&date_before=${params.dateEnd}`;
      }
      if (params.farm) {
        query += `&block__farm=${params.farm}`;
      }
      if (params.block) {
        query += `&block=${params.block}`;
      }
    }
    const dateList = await FloweringAPI.getFruitSizeDates(query);
    if (dateList.length > 0) {
      startDate = dateList[0].date;
      endDate = dateList[dateList.length - 1].date;
    }
    return {
      FruitSizeDateList: dateList,
      StartDate: startDate,
      EndDate: endDate,
      FruitSizeDateVersion: new Date().getTime()
    };
  }

  @Action
  async calculatePercentDmIncrease(params: {
    blockId: number;
    seasonId: number;
  }) {
    return await FloweringAPI.calculatePercentDmIncrease(
      params.blockId,
      params.seasonId
    );
  }

  @Action
  async updateSeasonsDmPercentIncrease(data: EstimatedDMPercentIncrease) {
    return await FloweringAPI.updateSeasonsDmPercentIncrease(data);
  }

  @MutationAction
  async getFruitCounts(params: {
    dateStart: string;
    dateEnd?: string;
    farm: number;
  }) {
    const fruitCounts = this.FruitCounts;
    const selectedBlock = this.SelectedBlock;
    const version = new Date().getTime();
    if (
      params.dateEnd &&
      params.dateEnd == params.dateStart &&
      fruitCounts[`${params.dateStart}_${params.farm}_${selectedBlock}`]
    ) {
      return { FruitCounts: fruitCounts, FruitCountLayerVersion: version };
    }
    let query = '';
    if (params) {
      query += '?';
      if (params.dateStart) {
        query += `&date_after=${params.dateStart}`;
      }
      if (params.dateEnd) {
        query += `&date_before=${params.dateEnd}`;
      }
      if (selectedBlock) {
        query += `&block=${selectedBlock}`;
      }
      if (params.farm) {
        query += `&block__farm=${params.farm}`;
      }
    }
    const newFruitCounts = await FloweringAPI.getFruitCounts(query);
    if (params.dateEnd && params.dateEnd != params.dateStart) {
      fruitCounts['ranged'] = newFruitCounts;
    } else {
      fruitCounts[
        `${params.dateStart}_${params.farm}_${selectedBlock}`
      ] = newFruitCounts;
    }
    return { FruitCounts: fruitCounts, FruitCountLayerVersion: version };
  }


  @MutationAction
  async createFruitCount(fruitCount: FruitCount) {
    const addedFruitCount = await FloweringAPI.createFruitCount(fruitCount);
    const fruitCounts = this.FruitCounts;
    fruitCounts.results.push(addedFruitCount);

    return {
      FruitCounts: fruitCounts,
      AddEditFruitCount: addedFruitCount
    };
  }

  @MutationAction
  async getFlowerEventHeatUnitGraph(params: {
    block: number;
    heatSumFormulaMethod?: string;
    startDate?: string;
    endDate?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.block) {
        query += `&block=${params.block}`;
      }
      if (params.heatSumFormulaMethod) {
        query += `&heat_sum_formula_type=${params.heatSumFormulaMethod}`;
      }
      if (params.startDate) {
        query += `&start_date=${params.startDate}`;
      }
      if (params.endDate) {
        query += `&end_date=${params.endDate}`;
      }
    }
    const flowerEventHeatUnitGraph = await FloweringAPI.getFlowerEventHeatUnitGraph(
      query
    );
    return { FlowerEventHeatUnitGraph: flowerEventHeatUnitGraph };
  }

  @MutationAction
  async getMinMaxTemperatureGraph(params: {
    block: number;
    startDate?: string;
    endDate?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.block) {
        query += `&block=${params.block}`;
      }
      if (params.startDate) {
        query += `&start_date=${params.startDate}`;
      }
      if (params.endDate) {
        query += `&end_date=${params.endDate}`;
      }
    }
    const flowerEventHeatUnitGraph = await FloweringAPI.getMinMaxTemperatureGraph(
      query
    );
    return { MinMaxTemperatureGraph: flowerEventHeatUnitGraph };
  }

  @MutationAction
  async setAddEditFlowerEvent(flowerEvent: FlowerEvent) {
    return { AddEditFlowerEvent: flowerEvent };
  }

  @MutationAction
  async getAllFlowerEvents(params?: {
    dateStart?: string;
    dateEnd?: string;
    block?: number;
    farm?: number;
    eventNum?: number | null;
    page?: number;
    search?: string;
    ordering?: string;
    pageSize?: number;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.page) {
        query += `&page=${params.page}`;
      }
      if (params.pageSize) {
        query += `&page_size=${params.pageSize}`;
      }
      if (params.ordering) {
        query += `&ordering=${params.ordering}`;
      }
      if (params.search) {
        query += `&search=${params.search}`;
      }
      if (params.dateStart) {
        query += `&date_after=${params.dateStart}`;
      }
      if (params.eventNum) {
        query += `&event_num=${params.eventNum}`;
      }
      if (params.dateEnd) {
        query += `&date_before=${params.dateEnd}`;
      }
      if (params.farm) {
        query += `&block__farm=${params.farm}`;
      }
      if (params.block) {
        query += `&block=${params.block}`;
      }
    }
    const flowerEvent = await FloweringAPI.getAllFlowerEvents(query);
    return { FlowerEvents: flowerEvent };
  }

  @MutationAction
  async getFlowerEventById(id: number) {
    const flowerEvent = await FloweringAPI.getFlowerEventById(id);
    return { AddEditFlowerEvent: flowerEvent };
  }

  @MutationAction
  async createFlowerEvent(floweringEvent: FlowerEvent) {
    const addedFlowerEvent = await FloweringAPI.createFlowerEvent(
      floweringEvent
    );
    const floweringEvents = this.FlowerEvents;
    floweringEvents.results.push(addedFlowerEvent);

    return {
      FlowerEvents: floweringEvents,
      AddEditFlowerEvent: addedFlowerEvent
    };
  }

  @MutationAction
  async updateFlowerEvent(floweringEvent: FlowerEvent) {
    const updatedFlowerEvent = await FloweringAPI.updateFlowerEvent(
      floweringEvent
    );

    const floweringEvents = this.FlowerEvents;
    const floweringEventIndex = floweringEvents.results.findIndex(
      x => x.id == floweringEvent.id
    );
    if (floweringEventIndex != -1) {
      floweringEvents.results.splice(
        floweringEventIndex,
        1,
        updatedFlowerEvent
      );
    }
    return {
      FlowerEvents: floweringEvents,
      AddEditFlowerEvent: updatedFlowerEvent
    };
  }

  @MutationAction
  async createMultipleFlowerEvent(floweringEvent: MultipleFlowerEvent) {
    const addedFlowerEvent = await FloweringAPI.createMultipleFlowerEvent(
      floweringEvent
    );
    const floweringEvents = this.FlowerEvents;
    floweringEvents.results.push(addedFlowerEvent);

    return {
      FlowerEvents: floweringEvents,
      AddEditFlowerEvent: addedFlowerEvent
    };
  }

  @Action
  async updateMultipleFlowerEvent(floweringEvent: MultipleFlowerEvent) {
    return await FloweringAPI.updateMultipleFlowerEvent(floweringEvent);
  }

  @MutationAction
  async deleteFlowerEvent(id: number) {
    await FloweringAPI.deleteFlowerEvent(id);

    const emptyFlowerEvent = this.emptyFlowerEvent;

    const floweringEvents = this.FlowerEvents;
    if (floweringEvents) {
      const floweringEventIndex = floweringEvents.results.findIndex(
        x => x.id == id
      );
      if (floweringEventIndex != -1) {
        floweringEvents.results.splice(floweringEventIndex, 1);
      }
    }

    return {
      FlowerEvents: floweringEvents,
      AddEditFlowerEvent: JSON.parse(JSON.stringify(emptyFlowerEvent))
    };
  }

  @MutationAction
  async getRawTransposedFlowerings(params?: {
    startDate?: string;
    endDate?: string;
    block?: number;
    farm?: number;
    eventNum?: number | null;
    page?: number;
    search?: string;
    ordering?: string;
    pageSize?: number;
    method?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.page) {
        query += `&page=${params.page}`;
      }
      if (params.pageSize) {
        query += `&page_size=${params.pageSize}`;
      }
      if (params.ordering) {
        query += `&ordering=${params.ordering}`;
      }
      if (params.search) {
        query += `&search=${params.search}`;
      }
      if (params.startDate) {
        query += `&date_after=${params.startDate}`;
      }
      if (params.eventNum) {
        query += `&event_num=${params.eventNum}`;
      }
      if (params.endDate) {
        query += `&date_before=${params.endDate}`;
      }
      if (params.farm) {
        query += `&block__farm=${params.farm}`;
      }
      if (params.block) {
        query += `&block=${params.block}`;
      }
      if (params.method) {
        query += `&method=${params.method}`;
      }
    }
    const rawTransposedFlowerings = await FloweringAPI.getRawTransposedFlowerings(
      query
    );
    return { RawTransposedFlowerings: rawTransposedFlowerings };
  }

  @MutationAction
  async getRawFloweringHarvestDate(params?: {
    farm?: number;
    block?: number;
    startDate?: string;
    endDate?: string;
    method?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.farm) {
        query += `&block__farm=${params.farm}`;
      }
      if (params.block) {
        query += `&block=${params.block}`;
      }
      if (params.startDate) {
        query += `&date_after=${params.startDate}`;
      }
      if (params.endDate) {
        query += `&date_before=${params.endDate}`;
      }
      if (params.method) {
        query += `&method=${params.method}`;
      }
    }
    const rawFloweringHarvestDate = await FloweringAPI.getRawFloweringHarvestDate(
      query
    );
    return { RawFloweringHarvestDate: rawFloweringHarvestDate };
  }

  @MutationAction
  async getYieldByWeekTransposed(params?: {
    farm?: number;
    block?: number;
    dateAfter?: string;
    dateBefore?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.farm) {
        query += `&block__farm=${params.farm}`;
      }
      if (params.block) {
        query += `&block=${params.block}`;
      }
      if (params.dateAfter) {
        query += `&date_after=${params.dateAfter}`;
      }
      if (params.dateBefore) {
        query += `&date_before=${params.dateBefore}`;
      }
    }
    console.log('flowering-store::getYieldByWeekTransposed()',params,query);
    const yieldByWeekTransposed = await FloweringAPI.getYieldByWeekTransposed(query);
    console.log('yieldByWeekTransposed',yieldByWeekTransposed);
    return { YieldByWeekTransposed: yieldByWeekTransposed };
  }

  @MutationAction
  async getFarmYieldByWeekTransposed(params?: {
    farm?: number;
    block?: number;
    dateAfter?: string;
    dateBefore?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.farm) {
        query += `&block__farm=${params.farm}`;
      }
      if (params.block) {
        query += `&block=${params.block}`;
      }
      if (params.dateAfter) {
        query += `&date_after=${params.dateAfter}`;
      }
      if (params.dateBefore) {
        query += `&date_before=${params.dateBefore}`;
      }
    }
    const yieldByWeekTransposed = await FloweringAPI.getFarmYieldByWeekTransposed(
      query
    );
    return { FarmYieldByWeekTransposed: yieldByWeekTransposed };
  }

  @Action
  async createRawFloweringData(data: RawFloweringData) {
    return await FloweringAPI.createRawFloweringData(data);
  }

  @Action
  async updateRawFloweringData(data: RawFloweringPatchData) {
    return await FloweringAPI.updateRawFloweringData(data);
  }

  @Action
  async downloadRawFloweringData(params: { data: FormData }) {
    const response = await FloweringAPI.downloadRawFloweringData(params.data);
    return response;
  }
  @MutationAction
  async getFlowerDistributionGraph(params: {
    block: number;
    startDate?: string;
    endDate?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.block) {
        query += `&block=${params.block}`;
      }
      if (params.startDate) {
        query += `&date_after=${params.startDate}`;
      }
      if (params.endDate) {
        query += `&date_before=${params.endDate}`;
      }
    }
    const floweringGraph = await FloweringAPI.getFlowerDistributionGraph(query);
    return { FlowerDistributionGraph: floweringGraph };
  }

  @MutationAction
  async getFlowerLinearGraph(params: {
    block: number;
    startDate?: string;
    endDate?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.block) {
        query += `&block=${params.block}`;
      }
      if (params.startDate) {
        query += `&date_after=${params.startDate}`;
      }
      if (params.endDate) {
        query += `&date_before=${params.endDate}`;
      }
    }
    const floweringGraph = await FloweringAPI.getFlowerLinearGraph(query);
    return { FlowerLinearGraph: floweringGraph };
  }

  @MutationAction
  async getFruitDistributionGraph(params: {
    block: number;
    startDate?: string;
    endDate?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.block) {
        query += `&block=${params.block}`;
      }
      if (params.startDate) {
        query += `&date_after=${params.startDate}`;
      }
      if (params.endDate) {
        query += `&date_before=${params.endDate}`;
      }
    }
    const graph = await FloweringAPI.getFruitDistributionGraph(query);
    return { FruitDistributionGraph: graph };
  }

  @MutationAction
  async getDryMatterDistributionGraph(params: {
    block: number;
    startDate?: string;
    endDate?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.block) {
        query += `&block=${params.block}`;
      }
      if (params.startDate) {
        query += `&date_after=${params.startDate}`;
      }
      if (params.endDate) {
        query += `&date_before=${params.endDate}`;
      }
    }
    const graph = await FloweringAPI.getDryMatterDistributionGraph(query);
    return { DryMatterDistributionGraph: graph };
  }

  @MutationAction
  async getDryMatterPercentIncreaseGraph(params: {
    block: number;
    startDate?: string;
    endDate?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.block) {
        query += `&block=${params.block}`;
      }
      if (params.startDate) {
        query += `&date_after=${params.startDate}`;
      }
      if (params.endDate) {
        query += `&date_before=${params.endDate}`;
      }
    }
    const graph = await FloweringAPI.getDryMatterPercentIncreaseGraph(query);
    return { DryMatterPercentIncreaseGraph: graph };
  }

  @MutationAction
  async getPackHouseGraph(params: {
    block: number;
    startDate?: string;
    endDate?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.block) {
        query += `&block=${params.block}`;
      }
      if (params.startDate) {
        query += `&date_after=${params.startDate}`;
      }
      if (params.endDate) {
        query += `&date_before=${params.endDate}`;
      }
    }
    const graph = await FloweringAPI.getPackHouseGraph(query);
    return { PackHouseGraph: graph };
  }
}

export default getModule(FloweringStore);
